
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "undewriter-credit-notes-list",
  components: {},
  props: { code: String },
  data() {
    return {
      creditNotes: [
        {
          clientName: "",
          policyNumber: "",
          serialNumber: "",
          amount: 0,
          status: "",
          transactionDate: "",
          narration: "",
          publicId: "",
          policyPublicId: "",
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageTitle("Credit Notes");
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    this.getCreditNotes();

    //Set page title
    document.title = "Credit Notes | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getCreditNotes() {
      //Fetch underwriter dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.UNDERWRITERS_CREDIT_NOTES_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.creditNotes = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
